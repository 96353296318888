.observerElement {
  position: absolute;

  &._top {
    top: 0px;
  }

  &._bottom {
    bottom: 0px;
  }

  &._hiddenPoint {
    height: 1px;
    width: 1px;
    opacity: 0;
  }
}
