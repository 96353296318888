@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

.categoryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
  cursor: pointer;
  width: 100%;
  color: var(--text-color-grey-1);
  max-width: 100%;

  &.loading {
    cursor: auto;
    .iconContainer {
      @include size(80px);
      @include placeholder;
    }

    .label {
      height: 24px;
      @include placeholder;
    }
  }

  .iconContainer {
    padding: var(--space-4);
    border-radius: 50%;
    background-color: var(--info-light-opaque-50);
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 var(--space-2);
    transition: all 0.2s;

    svg {
      @include size(48px);
      transition: all 0.2s;
    }
  }

  .label {
    @include label-md-bold;
    @include overflowedText(1);
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  @include from-phone {
    &:hover:not(.loading) {
      .iconContainer {
        padding: var(--space-3);
        background-color: var(--info-light);

        svg {
          @include size(56px);
        }
      }
    }
  }

  @include until-phone {
    &.loading {
      .iconContainer {
        @include size(64px);
      }

      .label {
        height: 20px;
      }
    }

    .iconContainer {
      padding: var(--space-3);
      margin: 0 calc(var(--space-2) - 1px);

      svg {
        @include size(40px);
      }
    }

    .label {
      @include label-sm-bold;
    }
  }
}
