@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

.badge {
  display: flex;
  align-items: center;
  gap: var(--space-1-half);

  &.size-small {
    @include text-xs-bold;

    --icon-width: 6.581px;
    --icon-height: 11.961px;
  }

  &.size-medium {
    @include text-sm-bold;

    --icon-width: 8.803px;
    --icon-height: 16px;
  }

  &.size-large {
    @include label-md-bold;

    --icon-width: 11.004px;
    --icon-height: 20px;
  }

  &.color-gold {
    --text-color: var(--gold-6);
  }

  &.color-white {
    --text-color: var(--white);
  }

  .text {
    color: var(--text-color);
  }

  .icon {
    fill: var(--text-color) !important;
    width: var(--icon-width);
    height: var(--icon-height);

    path {
      fill: var(--text-color) !important;
    }
  }

  .flipHorizontal {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
  }
}
