@import 'scss/mixins';

.pills {
  display: flex;
  margin-top: var(--space-2);
  padding: var(--space-1);
  overflow-x: auto;
  max-width: 100%;
  scrollbar-width: none;
  position: relative; // required for track event exposure

  @include from-phone {
    // To avoid overlap between pills arrows and the card slider arrows
    &.reducedWidth {
      max-width: calc(100% - 20%);

      svg path {
        fill: var(--neutral-2);
      }
    }
  }

  @include until-phone {
    // full-width pills container
    max-width: 100vw;
    padding: var(--space-1) 0;
    margin-right: calc(-1 * var(--page-side-space));
    margin-left: calc(-1 * var(--page-side-space));
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .pill {
    margin-bottom: 0 !important;
  }

  &.monochromeIcon {
    > div > div > button {
      &:hover:not(:first-child):not([data-selected='true']) {
        svg path {
          fill: transparent;
          &[stroke] {
            stroke: var(--neutral-1);
          }
        }
      }

      &:not(:first-child) {
        svg path {
          fill: transparent;
          &[stroke] {
            stroke: var(--neutral-2);
          }
        }
      }

      &[data-selected='true'] {
        svg path {
          &[stroke] {
            stroke: white;
          }
        }
      }
    }
  }
}
