@import 'scss/mixins';
@import 'scss/typography';

.productCard {
  border-radius: var(--border-radius-lg);
  background-color: var(--white);
  overflow: hidden;
  height: 186px;
  display: flex;
  flex-direction: row;
  position: relative;

  &.border-gold-3 {
    border: 1px solid var(--gold-3);

    &.pinned {
      border: 1px solid var(--gold-6);
      box-shadow: 0px 5px 16px 0px rgba(226, 174, 1, 0.5);
      background-color: var(--white);
    }
  }

  &:hover {
    .image {
      transform-origin: center;
      transform: scale(1.1);
      transition: all 0.25s ease-out;
      border: 1px solid var(--gold-6);
      box-shadow: 0px 5px 16px 0px rgba(226, 174, 1, 0.5);
    }
  }

  .imageContainer {
    position: relative;
    overflow: hidden;
    width: 124px;

    img {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
      object-fit: cover;
    }

    .wishlistBtn {
      position: absolute;
      top: var(--space-3);
      right: var(--space-3);
      padding: var(--space-1-half);
      height: fit-content;
      display: flex;
      aspect-ratio: 1 / 1;

      > button > svg {
        @include size(28px);
      }
    }
  }

  .callout {
    @include text-sm;
    @include overflowedText(1);
    max-width: calc(
      100% - 62px
    ); // 10px from left position + 8px from spacing between wishlist + 44px wishlist button from design
    background-color: var(--yellow-interaction-standard);
    box-shadow: var(--shadow-bottom-2);
    padding: 6px 10px;
    border-radius: var(--border-radius-smd);
    position: absolute;
    top: 12px;
    left: 12px;
    color: black;
    font-weight: var(--font-weight-bold);
  }

  .topTen {
    position: absolute;
    top: 0;
    left: 8px;
  }

  .body {
    padding: var(--space-3) var(--space-4);
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      flex-direction: column;
      gap: var(--space-1);

      .productName {
        @include label-sm-bold;
        @include overflowedText(2);
        @include until-phone {
          @include overflowedText(3);
        }

        color: var(--text-color-grey-1);
        word-break: break-word;
      }

      .rating {
        @include label-sm-bold;

        display: flex;
        align-items: center;
        gap: var(--space-1);
        color: var(--text-color-grey-1);

        &Icon {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding-top: 0.5px;
          @include size(12px);

          svg {
            @include size(10px);
            fill: var(--secondary-variant-1);

            path {
              stroke: var(--secondary-variant-1);
            }
          }
        }

        .reviewCount {
          font-weight: normal;
        }
      }

      .label {
        @include overflowedText(1);
        @include text-sm;

        margin-top: var(--space-1);
        flex-shrink: 1;
        flex-grow: 0;
        background-color: var(--info-light);
        color: var(--info-text);
        padding: var(--space-1) var(--space-2);
        border-radius: var(--border-radius-sm);
      }
    }

    .footer {
      .priceAndLabel {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: var(--space-2);
      }

      .fromPriceValue {
        @include text-xs;
        color: var(--text-color-grey-1);
        white-space: nowrap;
        margin-bottom: var(--space-1-half);
      }

      .priceValue {
        @include label-md-bold;
        flex: 1;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: var(--text-color-grey-1);
      }
    }
  }

  &.loading {
    .imageContainer {
      @include placeholder;
    }

    .body {
      .productName {
        @include placeholder;
        border-radius: var(--border-radius-32);
        height: 20px;
      }

      .label {
        @include placeholder;

        width: 30%;
        height: 20px;
      }

      .footer {
        margin-top: 14px;
        .priceAndLabel {
          @include placeholder;
          border-radius: var(--border-radius-32);
          height: 20px;
        }
      }
    }
  }
}
