@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.cardSliderContainer {
  @include until-phone {
    padding-left: 0;
    padding-right: 0;

    .mobileCtaBtn {
      margin-top: var(--space-6);
      margin-left: var(--page-side-space);
      margin-right: var(--page-side-space);
    }

    &::after {
      margin-left: var(--page-side-space);
      margin-right: var(--page-side-space);
    }
  }
}

.headerRow {
  display: flex;
  position: relative;
  margin-bottom: var(--space-6);
  flex-direction: row;

  &.withModifiers {
    margin-bottom: var(--space-2);
  }

  .leftColumn {
    flex-grow: 1;
    max-width: 100%;
  }

  .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
  }

  @include until-phone {
    padding: 0 var(--space-4);
    margin-bottom: var(--space-4);
    flex-direction: column;

    &.withModifiers {
      margin-bottom: var(--space-3);
    }
  }
}

.headingText {
  display: flex;
  flex-direction: column;
  color: var(--text-color-grey-1);

  span {
    @include header-3;
  }
}

.subHeaderText {
  @include label-md;
  font-weight: var(--font-weight-regular);
  margin-top: var(--space-2);
  display: flex;
  justify-content: space-between;

  @include until-phone {
    align-self: flex-start;
    margin-left: 0;
    @include text-sm;
    margin-top: var(--space-2);
  }
}

.activityCount {
  @include label-md;
  margin-right: calc(var(--space-13) * 2);
  margin-bottom: var(--space-1);
  font-weight: var(--font-weight-regular);

  @include until-phone {
    @include text-sm;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.seeAllCtaDesktop {
  align-self: flex-end;
  margin-right: var(--space-6);
  margin-bottom: var(--space-1);
}

.withModifiers .seeAllCtaDesktop {
  margin-bottom: var(--space-4);
}

.seeAllCtaMobileContainer {
  display: flex;
  justify-content: center;
  padding-top: var(--space-4);
  @include padding-x(var(--page-side-space));
}

.rightAlign {
  align-self: flex-end;
  text-align: end;
  margin-right: calc(var(--space-13) * 2);
}

.sliderArrow {
  position: absolute;
  top: -56px;
  will-change: background-color;
  transition: background-color 0.1s, border-color 0.1s;

  &Previous {
    right: 48px;
  }

  &Next {
    right: 0;
  }

  &.variant-vertical-center {
    top: 50%;

    &.sliderArrowPrevious {
      left: 0;
      right: unset;
      z-index: 1;
      transform: translate(-50%, -50%);
      &:hover:not(:disabled):not(:active):not(:focus),
      &:hover:focus {
        transform: translate(-50%, -50%);
      }
    }

    &.sliderArrowNext {
      transform: translate(50%, -50%);
      &:hover:not(:disabled):not(:active):not(:focus),
      &:hover:focus {
        transform: translate(50%, -50%);
      }
    }
  }
}

.cardSlider {
  .cards {
    height: 100%;

    > div {
      height: 100%;
    }
  }

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    padding: 0 var(--space-3);
    height: auto;

    > div {
      height: 100%;
    }

    @include until-phone {
      padding: 0 var(--space-2);
    }
  }

  :global(.slick-list) {
    margin-left: calc(var(--space-3) * -1);
    margin-right: calc(var(--space-3) * -1);

    & > div {
      // slick-slider sets card container width via inline css.
      // In case of single item, its being center aligned.
      // To keep all cards left aligned, setting min-width of container
      min-width: 100%;
    }

    @include until-phone {
      margin: 0 var(--space-2);
    }
  }

  &.interestCard {
    :global(.slick-slide) {
      padding: 0 var(--space-1);
    }
  }
}

:global(#hero) + .destinationCards {
  @include until-phone {
    margin-top: var(--space-8);
  }
}

.destinationCards {
  .headerRow {
    margin-bottom: var(--space-6);

    @include until-phone {
      margin-bottom: var(--space-2);
    }
  }

  // Due to large title size, need to adjust accordingly
  .seeAllCtaDesktop {
    margin-bottom: var(--space-2);
  }

  .sliderArrow {
    top: -62px;
  }
}
