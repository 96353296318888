@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';
@import 'scss/components/cards';

.card {
  @include card;
  @include fadeIn;

  @include from-phone() {
    &:hover {
      background: var(--white);
    }
  }

  &:hover {
    transform: translateY(-4px);
    filter: drop-shadow(var(--shadow-bottom-4));
  }

  &:focus-visible,
  &:focus {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
    }
  }

  &:focus-visible {
    box-shadow: var(--black-interaction-shadow-keyboard-focus);
  }

  display: grid;
  grid-template-columns: 0.6fr 1fr;

  @include until-tablet {
    display: flex;
    flex-direction: column;
  }

  /*#region loading state */
  &.loading {
    pointer-events: none;

    .image,
    .info div,
    h4 {
      @include regionLoading;
    }
    .info {
      button {
        @include regionLoading;
        border: transparent;
      }
    }
  }
  /*#endregion */

  .image {
    @include cardImage;
    border-radius: unset;
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);

    @include until-tablet {
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
    }
  }

  .info {
    padding: var(--space-6);
    color: var(--text-color-grey-1);
    border: 1px solid var(--card-border-color);
    border-left: unset;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);

    @include until-tablet {
      padding: var(--space-4);
      border-top: unset;
      border-left: 1px solid var(--card-border-color);
      border-top-right-radius: unset;
      border-bottom-right-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }

    h4 {
      @include header-4;
    }

    div {
      height: calc(var(--label-text-md) * 1.5 * 4);

      @include until-phone {
        height: calc(var(--label-text-sm) * 1.5 * 2);
      }
    }
    p {
      @include label-md;
      @include overflowedText(4);

      @include until-tablet {
        @include label-sm;
      }

      @include until-phone {
        @include overflowedText(2);
      }
    }

    button {
      margin-top: var(--space-4);
    }
  }
}
