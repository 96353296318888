@import 'scss/mixins';

@mixin card {
  position: relative;
  display: block;
  border-radius: var(--border-radius-md);
  will-change: transform, filter;
  transition: transform 0.2s, filter 0.2s;
  height: 100%;
}

@mixin cardImage {
  border-radius: var(--border-radius-md);
  object-fit: cover;
  // Prevent Image `alt` prop with URL overflowing the parent container
  overflow: hidden;
}
