@import 'scss/media-queries';
@import 'scss/mixins';

$dotsHeight: 13px;
$dotsMarginT: var(--space-2);

.sliderWrapper {
  @include until-phone {
    width: calc(100% + var(--paddingX) * 2);
    margin-left: calc(var(--paddingX) * -1);
    overflow: hidden;
  }

  :global {
    .slick-dots {
      position: relative;
      bottom: auto;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-top: $dotsMarginT;
      height: $dotsHeight;

      @include until-phone {
        margin-top: 0;
      }

      li {
        margin: 0;

        button:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 0;
          background: var(--neutral-4);
          opacity: 1;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          @include size(8px);
        }

        &.slick-active button:before {
          background: var(--secondary);
          opacity: 1;
          @include size(8px);
        }
      }
    }

    @include until-phone {
      .slick-slide {
        padding: 0 5px 10px;

        > div {
          $sliderHeightMobile: 350px;

          > img {
            width: 100%;
            height: $sliderHeightMobile;
            object-fit: cover;
          }

          ._loading {
            padding-top: var(--image-aspect-ratio, #{$sliderHeightMobile});
            @include placeholder;
          }
        }

        &.slick-active > div {
          .card-item {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.sliderArrow {
  --posX: 16px;
  z-index: 10;
  position: absolute;
  top: 50%;
  right: var(--posX);
  margin-top: calc(42px / -2);

  &.sliderDots {
    // if slider includes dots section, adjust the arrow position accordingly
    top: calc(50% - #{$dotsHeight} - #{$dotsMarginT});
  }

  &._prev {
    right: auto;
    left: var(--posX);
  }

  @include until-phone {
    display: none;
  }
}
