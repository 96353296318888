@import 'scss/typography';
@import 'scss/media-queries';

.container {
  height: 100%;
  padding-top: var(--space-2);

  @include until-phone {
    padding-top: 0;
  }
}

.ongoingCard {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: block;
  transition: transform 0.3s;

  .image {
    border-radius: var(--border-radius-lg);
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }

  &:hover {
    transform: translateY(calc(var(--space-2) * -1));
  }

  @include until-phone {
    &:hover {
      transform: translateY(0);
    }
  }
}

.signupPromoCard {
  border-radius: var(--border-radius-lg);
  padding: var(--space-6);
  position: relative;
  background-color: var(--primary-variant-2);
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(calc(var(--space-2) * -1));
  }

  .illustration {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(42%);
  }

  .content {
    color: var(--white);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .title {
      @include header-4;
      font-weight: var(--font-weight-bold);

      span {
        color: var(--primary);
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;
      gap: var(--space-3);
      position: relative;
      z-index: 1;

      button:hover:not(:disabled):not(:active) {
        transform: translateY(0);
      }

      a {
        text-decoration: underline;
      }
    }
  }

  @include until-phone {
    padding: var(--space-5);

    &:hover {
      transform: translateY(0);
    }

    .illustration {
      transform: translate(60%, 20%);
    }

    .content {
      .title {
        @include header-3;
        font-weight: var(--font-weight-bold);
      }
    }
  }
}
