@import 'scss/mixins';
@import 'scss/typography';

.rankLabel {
  position: relative;
  border-radius: 0px 0px var(--border-radius-sm) var(--border-radius-sm);
  background: linear-gradient(270deg, var(--gold-4) -2.75%, var(--gold-5) 37.94%, var(--gold-6) 132.9%);
  box-shadow: var(--shadow-bottom-2);
  padding: var(--space-1) var(--space-3);

  span {
    @include body-md-bold;

    font-weight: var(--font-weight-extra-bold);
    color: var(--white);
  }
}
