@import 'scss/mixins';
@import 'scss/typography';

.badgeContainer {
  display: flex;
  gap: var(--space-1);
  margin-bottom: var(--space-1-half);

  .destinationName {
    @include text-xs;

    color: var(--gold-6);
  }
}
