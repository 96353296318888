@import 'scss/typography';

.container {
  height: 100%;
  padding-top: var(--space-2);

  @include until-tablet {
    padding-top: 0;
  }
}

.destinationByRegionCard {
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--p-gray-color-5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  height: 100%;

  &:hover {
    transform: translateY(calc(var(--space-2) * -1));
  }

  .destinationImageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 6 / 7;
  }

  .destinationImage {
    object-fit: cover;
    aspect-ratio: 6 / 7;
  }

  .body {
    padding: var(--space-3);
    display: flex;
    align-items: center;
    gap: var(--space-1);
    color: var(--text-color-grey-1);

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
      @include label-sm-bold;
    }
  }

  @include until-tablet {
    &:hover {
      transform: translateY(0);
    }
  }
}
