@import 'scss/typography';

.btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;

  svg {
    margin-right: var(--space-2);
  }

  .label {
    position: relative;
  }

  &:hover .label::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
  }

  &.vertical {
    flex-direction: column;
    width: 41px;
    padding: 0 var(--space-1);

    svg {
      margin-right: unset;
    }

    .label {
      @include text-sm;
      font-weight: var(--font-weight-regular);

      &.selected {
        font-weight: var(--font-weight-bold);
      }
    }
  }
}

.heartBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--space-2);
}

.toastContainer {
  display: flex;
  justify-content: space-between;
}

.btnDesktop {
  gap: var(--space-2);
  // Required to remove the underline for Save button
  --btn-box-shadow: unset !important;
}

.btnSecondary {
  gap: var(--space-1) !important;

  @include until-phone {
    grid-column: 2;
    grid-row: 2;
    justify-self: start;
  }
}
