@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.container {
  position: relative;
  display: inline-block;

  &:not(:first-child) {
    margin-left: var(--space-4);

    @include until-phone {
      margin-left: var(--space-2);
    }
  }

  @include until-phone {
    display: inline-flex;
  }

  .item {
    display: flex;
    align-items: center;
    padding: var(--v-padding) var(--h-padding);
    border-radius: var(--border-radius-sm);

    &.item-mini {
      --v-padding: var(--space-1);
      --h-padding: var(--space-1);

      div {
        @extend .text-xs;
      }
    }
    &.item-small {
      --v-padding: var(--space-1);
      --h-padding: var(--space-2);

      div {
        @extend .text-sm;
      }
    }
    &.item-medium {
      --v-padding: var(--space-1);
      --h-padding: var(--space-2);

      div {
        @extend .text-sm;
      }
    }
    &.item-semi-large {
      --v-padding: var(--space-1);
      --h-padding: var(--space-2);

      div {
        @extend .label-sm;
      }
    }
    &.item-large {
      --v-padding: var(--space-1);
      --h-padding: var(--space-2);

      div {
        @extend .label-md;
      }
    }

    svg {
      display: block;
      margin-left: var(--space-1);
    }
  }

  &.blue {
    .item {
      background-color: var(--info-light);

      div {
        color: var(--text-color-kf);
      }

      svg {
        color: var(--text-color-kf) !important;
      }

      &.disabled {
        background-color: var(--black-interaction-disabled);

        div {
          color: var(--black-interaction-text-disabled-color);
        }

        svg {
          color: var(--black-interaction-text-disabled-color) !important;
        }
      }
    }
  }

  &.grey {
    .item {
      background-color: var(--neutral-5);

      div {
        color: var(--neutral-2);
      }

      svg {
        color: var(--neutral-2) !important;
      }
    }
  }

  &.dark-green {
    .item {
      --v-padding: var(--space-1-half);
      background-color: var(--dark-green);

      div {
        color: var(--white);
      }

      svg {
        color: var(--white) !important;
      }
    }
  }

  &.light-green {
    .item {
      background-color: var(--success-light);

      div {
        color: var(--dark-green);
      }

      svg {
        color: var(--dark-green) !important;
      }
    }
  }

  &.yellow {
    .item {
      background-color: var(--primary);

      div {
        color: var(--text-color);
      }

      svg {
        color: var(--text-color) !important;
      }

      &.disabled {
        background-color: var(--yellow-interaction-disabled);
        div {
          color: var(--neutral-9);
        }
      }
    }
  }
}
