@import 'scss/typography';

.kfMilesPrice {
  display: flex;
  align-items: flex-end;
  gap: var(--space-1);
  color: var(--text-color-grey-1);

  &.variant-price-block {
    &.size-medium {
      p {
        @include header-4;
      }

      span {
        @include label-md-bold;
        margin-bottom: var(--space-1-half);
      }
    }

    &.size-small {
      gap: var(--space-1-half);
      p {
        @include label-md-bold;
      }

      span {
        @include label-sm-bold;
        margin-bottom: var(--space-1-half);
      }
    }
  }

  &.variant-default {
    &.size-medium {
      p {
        @include label-md-bold;
      }
    }

    &.size-small {
      p {
        @include label-sm-bold;
      }
    }

    &.size-large {
      p {
        @include label-lg;
        font-weight: var(--font-weight-extra-bold);
      }
    }
  }

  @include until-phone {
    &.variant-default {
      &.size-medium {
        p {
          @include label-lg-bold;
        }
      }

      &.size-large {
        p {
          @include label-md-bold;
        }
      }
    }

    &.variant-price-block {
      &.size-small {
        span {
          @include text-sm-bold;
          margin-bottom: 3px;
        }
      }
    }
  }
}
