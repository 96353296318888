@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';
@import 'scss/components/cards';

.card {
  @include card;
  @include fadeIn;

  @include from-phone() {
    &:hover {
      background: var(--white);
    }

    &.isSliderItem:hover {
      filter: drop-shadow(var(--shadow-top-2));
      transform: translateY(calc(var(--space-1) * -1));
    }
  }

  /*#region loading state */
  &.loading {
    pointer-events: none;

    .productImage,
    .countryName,
    .description {
      @include regionLoading;
    }
  }
  /*#endregion */
}

.productImage {
  @include cardImage;
}

.productInfo {
  padding: var(--space-2) var(--space-4) var(--space-4);

  .countryName {
    @include label-lg-bold;
    @include overflowedText(1);
  }

  .description {
    @include label-sm;
    margin-top: var(--space-1);
    color: var(--text-color-grey-2);
  }
}
