@import 'scss/typography';

.container {
  height: 100%;
  padding-top: var(--space-2);
}

.exclusiveBenefitsCard {
  border: 1px solid var(--secondary-opaque-25);
  border-radius: var(--border-radius-lg);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(calc(var(--space-2) * -1));
  }

  .header {
    padding: var(--space-4) var(--space-6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--space-3);

    button {
      white-space: nowrap;

      &:hover:not(:disabled):not(:active) {
        transform: translateY(0);
      }
    }
  }

  .body {
    padding: var(--space-4) var(--space-6);
    background-color: var(--info-light-opaque-50);
    flex: 1;

    .subHeader {
      @include label-sm;
      color: var(--info-text);
      text-transform: uppercase;
      margin-bottom: var(--space-2);
      display: block;
    }

    .description {
      @include label-lg-bold;
      color: var(--info-text);
    }
  }

  .logo {
    height: 32px;
    max-width: 100%;

    &Container {
      width: 100%;
      max-width: 129px;
      display: flex;
      align-items: center;
    }
  }

  @include until-phone {
    &:hover {
      transform: translateY(0);
    }

    .logo {
      max-width: 113px;
    }

    .body {
      .subHeader {
        @include text-sm;
      }

      .description {
        @include label-md;
      }
    }
  }
}
